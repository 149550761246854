<template>

  <div class="form-group">

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="translationKeyTooltipToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Info
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body" style="border-top: 1px solid black">
          <p>
            {{ translationKeyTooltipModalText01 }}
          </p>
          <p>
            {{ translationKeyTooltipModalText02 }}
          </p>
          <p>
            {{ translationKeyTooltipModalText03 }}
          </p>
        </div>
      </div>
    </div>

    <label class="form-label mt-4" :hidden="!label || label.length === 0">
      {{ label }}

      <sup>
        <span class="badge rounded-pill bg-primary"
              id="translationKeyTooltip"
              type="button"
              @click="showTranslationKeyTooltipToast()"
              :title="translationKeyTooltipModalText01 + '\n' + translationKeyTooltipModalText02 + '\n' + translationKeyTooltipModalText03">
          ?
        </span>
      </sup>

      &nbsp;

      <div class="spinner-border spinner-border-sm text-info"
           :hidden="isReadonly || translationKeyAvailabilityCheck !== 1"
           role="status">
            <span class="visually-hidden">
              Checking translation key availability...
            </span>
      </div>

      <span :hidden="isReadonly || translationKeyAvailabilityCheck !== 2"
            class="key-availability-check-symbol"
            title="Translation key is available">
                        ✔️
      </span>

      <span :hidden="isReadonly || translationKeyAvailabilityCheck !== 3"
            title="You already have a translation stored under this key. Translation keys must be unique!"
            class="key-availability-check-symbol">
                        ⛔
      </span>

    </label>


    <div :class="isReadonly ? '' : 'input-group'">
      <input type="text"
             class="form-control"
             v-model="translationKey"
             :readonly="isReadonly"
             :placeholder="placeholder"
             v-on:keyup.enter="onPressedEnter()"
             v-on:input="translationKeyAvailabilityCheck = (translationKey ? 1 : 0);"
             @focus="$event.target.select()">

      <button class="btn btn-secondary"
              type="button"
              :disabled="!translationKey"
              :hidden="isReadonly"
              v-on:click="translationKey = ''; translationKeyAvailabilityCheck = 0;">
        X
      </button>
    </div>

  </div>

</template>

<script>

import {debounce} from "@/debounce";
import Constants from "@/constants";
import Config from "../../../public/js/config";
import {Toast} from "bootstrap";

export default {
  name: "TranslationKeyField",
  props: {
    isReadonly: {
      default: false,
      type: Boolean
    },
    label: {
      default: "Key",
      type: String
    },
    placeholder: {
      default: "",
      type: String
    }
  },
  methods: {
    onPressedEnter: function ()
    {
      this.$emit("onPressedEnter");
    },
    clear: function ()
    {
      this.translationKey = "";
      this.translationKeyAvailabilityCheck = 0;
    },
    setValue: function (string)
    {
      this.translationKey = string;
    },
    showTranslationKeyTooltipToast: function ()
    {
      new Toast(document.getElementById("translationKeyTooltipToast"), {delay: 7777}).show();
    },
    showLocaleIdentifierTooltipToast: function ()
    {
      new Toast(document.getElementById("localeIdentifierTooltipToast"), {delay: 7777}).show();
    }
  },
  data()
  {
    return {
      translationKey: "",
      translationKeyAvailabilityCheck: 0,
      translationKeyTooltipModalText01: "The translation key uniquely identifies your translation.",
      translationKeyTooltipModalText02: "Use it to access your translation (e.g. from your consuming clients' code) to fetch only the localized values from the server that you need for displaying your content.",
      translationKeyTooltipModalText03: "This can be anything up to 64 characters. It's typically going to be something like \"{MAIN_MENU_QUIT_BUTTON}\", \"user_action_button_01\", etc...",
    }
  },
  emits: ["onChangedTranslationKeyValue", "onPressedEnter"],
  watch: {
    translationKey: debounce(function (newValue)
    {
      if (!newValue)
      {
        this.$emit("onChangedTranslationKeyValue", "", false);
        return;
      }
      fetch(`${Config.webApiBaseUrl}/api/v1/translations/key/${newValue}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        if (!response.ok)
        {
          this.translationKeyAvailabilityCheck = 3;
          return;
        }

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
        {
          await this.$router.push("/logout");
          return;
        }

        const isAvailable = responseEnvelope.items[0].available === true;

        this.translationKeyAvailabilityCheck = isAvailable ? 2 : 3;
        this.$emit("onChangedTranslationKeyValue", newValue, isAvailable);

      }).catch(() =>
      {
        this.translationKeyAvailabilityCheck = 3;
      });
    }, 1024)
  }
}
</script>

<style scoped>

.key-availability-check-symbol
{
  cursor: help;
}

.key-availability-check-symbol:hover
{
  filter: brightness(80%);
}

#translationKeyTooltip
{
  margin-left: 4px;
  scale: 96%;
  cursor: help;
  font-weight: bold;
}

</style>
