export default class Constants
{
    static get localStorageKeyDarkTheme()
    {
        return "GlitchedLocaleDarkTheme"
    }

    static get localStorageKeyAuthToken()
    {
        return "GlitchedLocaleAuthToken"
    }

    static get localStorageKeyDefibrillatorToken()
    {
        return "GlitchedLocaleDefibrillatorToken"
    }

    static get localStorageKeyServerVersionNumber()
    {
        return "GlitchedLocaleServerVersionNumber";
    }

    static get localStorageKeyUserId()
    {
        return "GlitchedLocaleUserId";
    }

    static get localStorageKeyLoginReturnUrl()
    {
        return "GlitchedLocaleLoginReturnUrl";
    }

    static get localStorageKeyUsersListPageSize()
    {
        return "GlitchedLocaleUsersListPageSize";
    }

    static get localStorageKeyTranslationsListPageSize()
    {
        return "GlitchedLocaleTranslationsListPageSize";
    }

    static get settingsDefaultDarkTheme()
    {
        return true;
    }

    static get eventNameChangedTheme()
    {
        return "changedTheme";
    }

    static get authTokenRefreshIntervalSeconds()
    {
        return 420;
    }

    /*

    static get commonLocalesList()
    {
        return [
            "af_ZA.UTF-8",
            "am_ET.UTF-8",
            "ar_AE.UTF-8",
            "ar_BH.UTF-8",
            "ar_DZ.UTF-8",
            "ar_EG.UTF-8",
            "ar_IQ.UTF-8",
            "ar_JO.UTF-8",
            "ar_KW.UTF-8",
            "ar_LB.UTF-8",
            "ar_LY.UTF-8",
            "ar_MA.UTF-8",
            "ar_OM.UTF-8",
            "ar_QA.UTF-8",
            "ar_SA.UTF-8",
            "ar_SD.UTF-8",
            "ar_SY.UTF-8",
            "ar_TN.UTF-8",
            "ar_YE.UTF-8",
            "as_IN.UTF-8",
            "az_az.UTF-8",
            "ba_RU.UTF-8",
            "be_BY.UTF-8",
            "bg_BG.UTF-8",
            "bn_BD.UTF-8",
            "bn_IN.UTF-8",
            "bo_CN.UTF-8",
            "br_FR.UTF-8",
            "ca_ES.UTF-8",
            "co_FR.UTF-8",
            "cs_CZ.UTF-8",
            "cy_GB.UTF-8",
            "da_DK.UTF-8",
            "de_AT.UTF-8",
            "de_CH.UTF-8",
            "de_DE.UTF-8",
            "de_LI.UTF-8",
            "de_LU.UTF-8",
            "dv_MV.UTF-8",
            "el_CY.UTF-8",
            "el_GR.UTF-8",
            "en_AU.UTF-8",
            "en_BZ.UTF-8",
            "en_CA.UTF-8",
            "en_cb.UTF-8",
            "en_GB.UTF-8",
            "en_IE.UTF-8",
            "en_IN.UTF-8",
            "en_JM.UTF-8",
            "en_MT.UTF-8",
            "en_MY.UTF-8",
            "en_NZ.UTF-8",
            "en_PH.UTF-8",
            "en_SG.UTF-8",
            "en_TT.UTF-8",
            "en_US.UTF-8",
            "en_ZA.UTF-8",
            "en_ZW.UTF-8",
            "es_AR.UTF-8",
            "es_BO.UTF-8",
            "es_CL.UTF-8",
            "es_CO.UTF-8",
            "es_CR.UTF-8",
            "es_DO.UTF-8",
            "es_EC.UTF-8",
            "es_ES.UTF-8",
            "es_GT.UTF-8",
            "es_HN.UTF-8",
            "es_MX.UTF-8",
            "es_NI.UTF-8",
            "es_PA.UTF-8",
            "es_PE.UTF-8",
            "es_PR.UTF-8",
            "es_PY.UTF-8",
            "es_SV.UTF-8",
            "es_US.UTF-8",
            "es_UY.UTF-8",
            "es_VE.UTF-8",
            "et_EE.UTF-8",
            "eu_ES.UTF-8",
            "fa_IR.UTF-8",
            "fi_FI.UTF-8",
            "fo_FO.UTF-8",
            "fr_BE.UTF-8",
            "fr_CA.UTF-8",
            "fr_CH.UTF-8",
            "fr_FR.UTF-8",
            "fr_LU.UTF-8",
            "fr_MC.UTF-8",
            "fy_NL.UTF-8",
            "ga_IE.UTF-8",
            "gd_GB.UTF-8",
            "gd_ie.UTF-8",
            "gl_ES.UTF-8",
            "gu_IN.UTF-8",
            "he_IL.UTF-8",
            "hi_IN.UTF-8",
            "hr_BA.UTF-8",
            "hr_HR.UTF-8",
            "hu_HU.UTF-8",
            "hy_AM.UTF-8",
            "id_ID.UTF-8",
            "ig_NG.UTF-8",
            "ii_CN.UTF-8",
            "in_ID.UTF-8",
            "is_IS.UTF-8",
            "it_CH.UTF-8",
            "it_IT.UTF-8",
            "iw_IL.UTF-8",
            "ja_JP.UTF-8",
            "ka_GE.UTF-8",
            "kk_KZ.UTF-8",
            "kl_GL.UTF-8",
            "km_KH.UTF-8",
            "kn_IN.UTF-8",
            "ko_KR.UTF-8",
            "ky_KG.UTF-8",
            "lb_LU.UTF-8",
            "lo_LA.UTF-8",
            "lt_LT.UTF-8",
            "lv_LV.UTF-8",
            "mi_NZ.UTF-8",
            "mk_MK.UTF-8",
            "ml_IN.UTF-8",
            "mn_MN.UTF-8",
            "mr_IN.UTF-8",
            "ms_BN.UTF-8",
            "ms_MY.UTF-8",
            "mt_MT.UTF-8",
            "nb_NO.UTF-8",
            "ne_NP.UTF-8",
            "nl_BE.UTF-8",
            "nl_NL.UTF-8",
            "nn_NO.UTF-8",
            "no_no.UTF-8",
            "oc_FR.UTF-8",
            "or_IN.UTF-8",
            "pa_IN.UTF-8",
            "pl_PL.UTF-8",
            "ps_AF.UTF-8",
            "pt_BR.UTF-8",
            "pt_PT.UTF-8",
            "rm_CH.UTF-8",
            "ro_mo.UTF-8",
            "ro_RO.UTF-8",
            "ru_mo.UTF-8",
            "ru_RU.UTF-8",
            "rw_RW.UTF-8",
            "sa_IN.UTF-8",
            "se_FI.UTF-8",
            "se_NO.UTF-8",
            "se_SE.UTF-8",
            "si_LK.UTF-8",
            "sk_SK.UTF-8",
            "sl_SI.UTF-8",
            "sq_AL.UTF-8",
            "sr_BA.UTF-8",
            "sr_CS.UTF-8",
            "sr_ME.UTF-8",
            "sr_RS.UTF-8",
            "sr_sp.UTF-8",
            "sv_FI.UTF-8",
            "sv_SE.UTF-8",
            "sw_KE.UTF-8",
            "ta_IN.UTF-8",
            "te_IN.UTF-8",
            "th_TH.UTF-8",
            "tk_TM.UTF-8",
            "tn_ZA.UTF-8",
            "tr_TR.UTF-8",
            "tt_RU.UTF-8",
            "ug_CN.UTF-8",
            "uk_UA.UTF-8",
            "ur_PK.UTF-8",
            "uz_uz.UTF-8",
            "vi_VN.UTF-8",
            "wo_SN.UTF-8",
            "xh_ZA.UTF-8",
            "yo_NG.UTF-8",
            "zh_CN.UTF-8",
            "zh_HK.UTF-8",
            "zh_MO.UTF-8",
            "zh_SG.UTF-8",
            "zh_TW.UTF-8",
            "zu_ZA"
        ];
    }

    */
}
