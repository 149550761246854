<template>

  <Nav/>

  <div class="container">
    <h1>
      Translations
    </h1>

    <button style="margin-top: 16px"
            class="btn btn-outline-info"
            title="Click here to create a new translation"
            v-on:click="createNewTranslation()">
      Create new translation
    </button>

    &nbsp;

    <button style="margin-top: 16px"
            class="btn btn-outline-success"
            title="Click here to export + download your translations (or a subset thereof) to an Excel spreadsheet."
            v-on:click="openExportTranslationsToExcelModal()">
      Export to Excel
    </button>

    &nbsp;

    <button style="margin-top: 16px"
            class="btn btn-outline-success"
            title="Click here to upload + import your translations from an Excel spreadsheet."
            v-on:click="openImportTranslationsFromExcelModal()">
      Import from Excel
    </button>

    <table class="table table-hover table-striped"
           style="margin-top: 16px">
      <thead>
      <tr>
        <th scope="col">
          Key
          <span class="pagination-order-icon"
                @click="toggleSortByColumn(0)">
            {{ orderBy === 0 ? '🔼' : orderBy === 1 ? '🔽' : '⏺' }}
          </span>
        </th>
        <th scope="col">
          Expires on
          <span class="pagination-order-icon"
                @click="toggleSortByColumn(4)">
            {{ orderBy === 4 ? '🔼' : orderBy === 5 ? '🔽' : '⏺' }}
          </span>
        </th>
        <th scope="col"
            class="hide-on-mobile">
          Created on
          <span class="pagination-order-icon"
                @click="toggleSortByColumn(2)">
            {{ orderBy === 2 ? '🔼' : orderBy === 3 ? '🔽' : '⏺' }}
          </span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="translation in translations"
          :key="translation.key"
          :id="`translationsListRow_${translation.id}`"
          @click="editTranslation(translation)"
          :class="translation.expirationUTC && (translation.expirationUTC * 1000) - new Date().getTime() < 172800000 ? 'table-warning' : (darkTheme ? 'table-secondary' : '')">
        <th scope="row">
          {{ translation.key }} &nbsp;<span style="font-size: 0.42rem;vertical-align: middle">{{
            translation.expirationUTC && translation.expirationUTC !== 0 && translation.expirationUTC < getUtcNow() ? "🔴" : translation.complete ? "🟢" : "🟡"
          }}</span>
        </th>
        <td>
          {{
            translation.expirationUTC ? new Date(translation.expirationUTC * 1000).toUTCString() : "(does not expire)"
          }}
        </td>
        <td class="hide-on-mobile">
          {{ new Date(translation.creationUTC * 1000).toUTCString() }}
        </td>
      </tr>
      </tbody>
    </table>

    <div class="pagination-group">

      <div class="btn-group"
           role="group">
        <button type="button"
                :disabled="page <= 1"
                :class="darkTheme ? 'btn btn-secondary' : 'btn btn-primary'"
                title="Load the previous page of translations"
                @click="prevPage()">
          &nbsp;&laquo;&nbsp;
        </button>

        <button :class="`btn btn-${darkTheme ? 'secondary' : 'primary'} btn-page-indicator-void`">
          {{ page }} / {{ pageCount }}
        </button>

        <button type="button"
                :disabled="page === pageCount"
                :class="darkTheme ? 'btn btn-secondary' : 'btn btn-primary'"
                title="Load the next page of translations"
                @click="nextPage()">
          &nbsp;&raquo;&nbsp;
        </button>
      </div>

      <div class="dropdown page-size-dropdown">
        <button :class="`btn btn-${darkTheme ? 'secondary' : 'primary'} dropdown-toggle`"
                type="button"
                style="width: 100%;"
                id="dropdownMenuButtonPageSize"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          Page size: {{ pageSize }} &nbsp;
        </button>
        <ul class="dropdown-menu"
            aria-labelledby="dropdownMenuButtonPageSize">
          <li>
            <button class="dropdown-item"
                    type="button"
                    @click="pageSize = 5; refreshTranslationsList();">
              5
            </button>
          </li>
          <li>
            <button class="dropdown-item"
                    type="button"
                    @click="pageSize = 10; refreshTranslationsList();">
              10
            </button>
          </li>
          <li>
            <button class="dropdown-item"
                    type="button"
                    @click="pageSize = 25; refreshTranslationsList();">
              25
            </button>
          </li>
          <li>
            <button class="dropdown-item"
                    type="button"
                    @click="pageSize = 50; refreshTranslationsList();">
              50
            </button>
          </li>
        </ul>
      </div>

      <div class="form-group">
        <div class="input-group">
          <input type="text"
                 class="form-control"
                 v-model="keyFilter"
                 placeholder="Search by translation key"
                 @focus="$event.target.select()">

          <button type="button"
                  class="btn btn-secondary"
                  :disabled="!keyFilter"
                  v-on:click="keyFilter = '';">
            X
          </button>
        </div>
      </div>

    </div>

    <!------------------------- MODALS ------------------------->

    <div class="modal fade"
         id="editTranslationModal"
         tabindex="-1"
         data-bs-keyboard="false"
         data-bs-backdrop="static"
         aria-labelledby="editTranslationModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="editTranslationModalLabel">
              {{ editedTranslation ? "Edit translation" : "Create new translation" }}
            </h5>

          </div>

          <div class="modal-body">

            <div v-if="loadingTranslation">
              <h5>
                Fetching translation data from server...
              </h5>
            </div>

            <fieldset>

              <div class="row">

                <div class="col-12 col-sm-6">

                  <TranslationKeyField ref="editedTranslationKey"
                                       :is-readonly="!!editedTranslation"
                                       @onPressedEnter="editedTranslation ? submitTranslationModificationRequest() : submitTranslationCreationRequest()"
                                       @onChangedTranslationKeyValue="onChangedTranslationKeyValue"/>
                </div>

                <div class="col-12 col-sm-6">

                  <ExpirationField ref="editedTranslationExpirationUTC"
                                   @onChangedExpirationUtcValue="onChangedExpirationUtcValue"/>
                </div>

              </div>

              <div class="form-group"
                   style="margin-top: -12px">

                <label for="translationDescriptionTooltip"
                       class="form-label mt-4">

                  Description

                  <sup>
                      <span class="badge rounded-pill bg-primary"
                            id="translationDescriptionTooltip"
                            type="button"
                            @click="showTranslationDescriptionTooltipToast()"
                            :title="translationDescriptionTooltipModalText01 + '\n' + translationDescriptionTooltipModalText02 + '\n' + translationDescriptionTooltipModalText03">
                        ?
                      </span>
                  </sup>
                </label>

                <textarea class="form-control"
                          id="editedTranslationDescriptionField"
                          v-model="editedTranslationDescription"
                          rows="3"
                          maxlength="256">
                  </textarea>
              </div>

            </fieldset>

            <label class="form-label mt-4">
              Locales
            </label>

            <br/>

            <div class="dropdown">
              <button class="btn btn-outline-info dropdown-toggle"
                      type="button"
                      id="dropdownMenuAddNewLocale"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                Add new
              </button>
              <ul class="dropdown-menu"
                  aria-labelledby="dropdownMenuAddNewLocale">
                <li v-for="localeId in defaultLocales"
                    :key="localeId">
                  <a class="dropdown-item dropdown-item-locale-id"
                     @click="onClickedAddNewLocale(localeId)">
                    {{ localeId }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item dropdown-item-locale-id"
                     @click="onClickedAddNewLocale()">
                    (Other)
                  </a>
                </li>
              </ul>
              <button class="btn btn-outline-info"
                      type="button"
                      style="margin-left: 12px;"
                      @click="onClickedAddAllLocales()"
                      title="Adds all locales at once. You can change the list of locales to add here in your account settings under 'Change default locales'"
                      id="buttonAddAllLocales">
                Add all
              </button>
            </div>

            <div v-if="editedTranslationTranslations || editedTranslationTranslations.length !== 0">

              <div class="accordion"
                   id="accordionTranslationLocales">

                <div v-for="translation in editedTranslationTranslations"
                     :key="translation"
                     class="accordion-item">

                  <h2 class="accordion-header"
                      :id="editedTranslationTranslations.indexOf(translation)">

                    <button class="accordion-button collapsed"
                            type="button"
                            aria-expanded="false"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#collapse_${editedTranslationTranslations.indexOf(translation)}`"
                            :aria-controls="`collapse_${editedTranslationTranslations.indexOf(translation)}`">
                        <span :id="`label_${editedTranslationTranslations.indexOf(translation)}`">
                          {{ Object.keys(translation)[0] }}
                        </span>
                    </button>

                  </h2>

                  <div :id="`collapse_${editedTranslationTranslations.indexOf(translation)}`"
                       :aria-labelledby="editedTranslationTranslations.indexOf(translation)"
                       class="accordion-collapse collapse"
                       data-bs-parent="#accordionTranslationLocales">

                    <div class="accordion-body">

                      <div class="form-group">

                        <label class="form-label">
                          Locale identifier

                          <sup>
                            <span class="badge rounded-pill bg-primary"
                                  id="localeIdentifierTooltip"
                                  type="button"
                                  @click="showLocaleIdentifierTooltipToast()"
                                  :title="localeIdentifierTooltipModalText01 + '\n' + localeIdentifierTooltipModalText02 + '\n' + localeIdentifierTooltipModalText03">
                              ?
                            </span>
                          </sup>
                        </label>

                        <div class="row">
                          <div class="col-6">
                            <input type="text"
                                   @focus="$event.target.select()"
                                   @input="onChangeLocaleIdentifier(translation, $event)"
                                   :value="Object.keys(translation)[0]"
                                   class="form-control">
                          </div>
                          <div class="col-6">
                            <button type="button"
                                    @click="onClickedRemoveLocale(translation)"
                                    class="btn btn-danger">
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">

                        <label class="form-label mt-2">
                          Translation
                        </label>

                        <textarea rows="3"
                                  class="form-control"
                                  @input="onChangeLocaleTranslation(translation, $event)"
                                  :value="Object.values(translation)[0]">
                          </textarea>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>


          </div>

          <div class="modal-footer">

            <div class="spinner-border spinner-border-sm text-info mr-auto"
                 :hidden="!translationModificationPending"
                 role="status">

                  <span class="visually-hidden">
                    Work in progress...
                  </span>
            </div>

            <button type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    @click="clearTranslationModalFields(); hideTranslationModalToasts();"
                    :disabled="translationModificationPending">
              {{ editedTranslation ? "Close" : "Cancel" }}
            </button>

            <button type="button"
                    class="btn btn-danger"
                    @click="onClickedDeleteTranslation()"
                    v-if="editedTranslation && editedTranslationId"
                    :disabled="translationModificationPending">
              Delete
            </button>

            <button type="button"
                    v-if="!loadingTranslation"
                    :disabled="translationModificationPending || !editedTranslationKey || !noInvalidTranslationKeys || !editedTranslationTranslations || editedTranslationTranslations.length === 0 || translationModificationCooldown"
                    :class="editedTranslation ? 'btn btn-primary' : 'btn btn-success'"
                    v-on:click="editedTranslation ? submitTranslationModificationRequest() : submitTranslationCreationRequest()">

              {{ editedTranslation ? "Save" : "Create" }}

            </button>

          </div>
        </div>
      </div>

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="localeIdentifierTooltipToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Info
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body"
               style="border-top: 1px solid black">
            <p>
              {{ localeIdentifierTooltipModalText01 }}
            </p>
            <p>
              {{ localeIdentifierTooltipModalText02 }}
            </p>
            <p>
              {{ localeIdentifierTooltipModalText03 }}
            </p>
          </div>
        </div>
      </div>

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="translationDescriptionTooltipToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Info
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body"
               style="border-top: 1px solid black">
            <p>
              {{ translationDescriptionTooltipModalText01 }}
            </p>
            <p>
              {{ translationDescriptionTooltipModalText02 }}
            </p>
            <p>
              {{ translationDescriptionTooltipModalText03 }}
            </p>
          </div>
        </div>
      </div>

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="translationCreationRequestFailedToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Failure <span>🔴️</span>
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Translation creation request rejected.
            Please try again later, or contact the site's administrator if this keeps happening!
          </div>
        </div>
      </div>

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="translationModificationRequestFailedToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Failure <span>🔴️</span>
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Translation modification request rejected.
            Please try again later, or contact the site's administrator if this keeps happening!
          </div>
        </div>
      </div>

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="translationModificationRequestSucceededToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Success <span>✔️</span>
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Translation modification request accepted.
            Your changes should be available soon: clients
            will get the new values on their next fetch.
          </div>
        </div>
      </div>

    </div>

    <div class="modal fade"
         id="exportToExcelModal"
         tabindex="-1"
         data-bs-keyboard="false"
         data-bs-backdrop="static"
         aria-labelledby="exportToExcelModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="exportToExcelModalLabel">
              Export translations to Excel
            </h5>

          </div>

          <div class="modal-body">

            <p>
              Translations can be exported to an Excel spreadsheet.
            </p>

            <p>
              To export all the translations associated with your account, leave the translation key filter field empty.
            </p>

            <p>
              If you want to export only a sub-set of your translations, enter the desired key filter value before
              clicking on "Export".
            </p>

            <p>
              The key filter behaves exactly like the one found in the translation overview's search bar: you can
              preview the result set there before exporting if you want to be sure.
            </p>

            <div class="form-group">
              <div class="input-group">
                <input type="text"
                       class="form-control"
                       v-model="exportKeyFilter"
                       style="max-width: 512px"
                       title="Only export translations whose localization key matches this search filter."
                       placeholder="Translation key filter"
                       @focus="$event.target.select()">

                <button type="button"
                        class="btn btn-secondary"
                        :disabled="!exportKeyFilter"
                        v-on:click="exportKeyFilter = '';">
                  X
                </button>
              </div>
            </div>

          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    @click="hideExportToExcelToasts();">
              Close
            </button>

            <button type="button"
                    class="btn btn-success"
                    :disabled="exportToExcelPending"
                    v-on:click="exportToExcel()">
              Export
            </button>

          </div>
        </div>
      </div>

    </div>

    <div class="modal fade"
         id="importFromExcelModal"
         tabindex="-1"
         data-bs-keyboard="false"
         data-bs-backdrop="static"
         aria-labelledby="importFromExcelModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="importFromExcelModalLabel">
              {{ importFromExcelPending ? "Importing translations from Excel..." : "Import translations from Excel" }}
            </h5>

          </div>

          <div class="modal-body">

            <p>
              Translations can be imported from an Excel spreadsheet.
            </p>

            <p>
              Please note that any <strong>existing translations</strong> (with matching translation key) <strong>will
              be overwritten</strong> by the Excel importer process without asking for any confirmation!
            </p>

            <p>
              The spreadsheet should have the following format:
            </p>

            <table class="table table-hover">
              <thead>
              <tr style="cursor: default !important;">
                <th scope="col">Localization key</th>
                <th scope="col">en_US.UTF-8</th>
                <th scope="col">de_DE.UTF-8</th>
                <th scope="col"
                    class="hide-on-mobile">it_IT.UTF-8
                </th>
                <th scope="col"
                    class="hide-on-mobile">(etc...)
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class=""
                  style="cursor: default !important;">
                <th>confirm_button</th>
                <td>Confirm</td>
                <td>Bestätigen</td>
                <td class="hide-on-mobile">Conferma</td>
                <td class="hide-on-mobile">...</td>
              </tr>
              <tr class=""
                  style="cursor: default !important;">
                <th>cancel_button</th>
                <td>Cancel</td>
                <td>Abbrechen</td>
                <td class="hide-on-mobile">Annulla</td>
                <td class="hide-on-mobile">...</td>
              </tr>
              <tr class=""
                  style="cursor: default !important;">
                <th>delete_button</th>
                <td>Delete</td>
                <td>Löschen</td>
                <td class="hide-on-mobile">Elimina</td>
                <td class="hide-on-mobile">...</td>
              </tr>
              <tr class=""
                  style="cursor: default !important;">
                <th>(etc...)</th>
                <td>...</td>
                <td>...</td>
                <td class="hide-on-mobile">...</td>
                <td class="hide-on-mobile">...</td>
              </tr>
              </tbody>
            </table>

            <br/>

            <div class="form-group">
              <label for="excelFileToImport"
                     class="form-label mt-4">
                Translations Excel Spreadsheet - File upload
              </label>
              <input class="form-control"
                     type="file"
                     id="excelFileToImport"
                     style="max-width: 512px">
            </div>

          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    @click="hideImportFromExcelToasts();">
              Close
            </button>

            <a type="button"
               class="btn btn-primary"
               :href="excelTemplateFileLink"
               title="Download an example Excel spreadsheet as a template for you to fill in. It comes with a pre-formatted table in it with a few empty rows. Expand or shrink table as needed, just make sure to follow the above format requirements."
               download="glitched-locale-excel-template.xlsx">
              Download template
            </a>

            <button type="button"
                    class="btn btn-success"
                    :disabled="importFromExcelPending"
                    v-on:click="importFromExcel()">
              Import
            </button>

          </div>
        </div>
      </div>

      <div class="position-fixed bottom-0 end-0 p-3"
           style="z-index: 11">
        <div id="importFromExcelFailedToast"
             class="toast"
             role="alert"
             aria-live="assertive"
             aria-atomic="true">
          <div class="toast-header">
            <strong class="me-auto">
              Failure <span>🔴️</span>
            </strong>
            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Failed to import translations from uploaded Excel spreadsheet: {{
              importFromExcelErrorMessage ? importFromExcelErrorMessage : 'reason unknown. Please ensure that the provided Excel spreadsheet has the correct format and values.'
            }}
          </div>
        </div>
      </div>

    </div>

    <!------------------------- TOASTS ------------------------->

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="translationCreationRequestSucceededToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Translation has been created successfully under the key "{{ editedTranslationKey }}".
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="translationDeletionRequestSucceededToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>✔️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Translation "{{ editedTranslationKey }}" has been deleted successfully:
          its quota will be freed within the next hour. Until then, the key won't be available.
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="translationDeletionRequestFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Translation deletion request failed.
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="translationFetchRequestFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Failed to fetch translation data.
          Please try again later, or contact the site's administrator if this keeps happening!
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="exportToExcelFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>🔴️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Failed to export translations to Excel:
          {{ exportToExcelErrorMessage ? exportToExcelErrorMessage : 'reason unknown' }}
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="exportToExcelSucceededToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>🟢</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Translations exported to Excel successfully: your download should start any moment now...
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="importFromExcelSucceededToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Success <span>🟢</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Translations imported from uploaded Excel spreadsheet successfully.
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Constants from "@/constants";
import Nav from "@/components/Nav";
import Config from "../../public/js/config";
import {pageCountFromTotal} from "@/util";
import ExpirationField from "@/components/form-fields/ExpirationField";
import {Modal, Toast} from "bootstrap";
import TranslationKeyField from "@/components/form-fields/TranslationKeyField";
import {debounce} from "@/debounce";
import {getUtcNow} from "@/utc";
import {refreshAuthToken, whoAmI} from "@/auth";

export default {
  name: "Translations",
  components: {Nav, TranslationKeyField, ExpirationField},
  data()
  {
    return {
      page: 1,
      pageSize: 10,
      pageCount: 1,
      orderBy: 0,
      keyFilter: "",
      loadingTranslation: false,
      loadingTranslations: false,
      translations: [],
      totalTranslationsCount: 0,
      translationModal: null,
      exportToExcelModal: null,
      importFromExcelModal: null,
      exportToExcelPending: false,
      importFromExcelPending: false,
      exportToExcelErrorMessage: "",
      importFromExcelErrorMessage: "",
      exportKeyFilter: "",
      translationModificationPending: false,
      translationModificationCooldown: false,
      editedTranslation: null,
      editedTranslationId: "",
      editedTranslationKey: "",
      editedTranslationOwnerId: "",
      editedTranslationDescription: "",
      editedTranslationCreationUTC: 0,
      editedTranslationExpirationUTC: 0,
      editedTranslationLastModificationUTC: 0,
      editedTranslationTranslations: [],
      noInvalidTranslationKeys: true,
      translationDescriptionTooltipModalText01: "You can use the description field for your own personal notes.",
      translationDescriptionTooltipModalText02: "It can turn out to be useful e.g. to describe the translation entry, and/or to add instructions and memos for later.",
      translationDescriptionTooltipModalText03: "Keep it short though: 256 characters max!",
      localeIdentifierTooltipModalText01: "The locale identifier is what clients use to distinguish and identify translations.",
      localeIdentifierTooltipModalText02: "Typically, these come in the form of a standardized string such as the POSIX ISO/IEC 15897 standard (e.g. \"en_US.UTF-8\" for American English, \"en_AU.UTF-8\" for Australian English, etc...)",
      localeIdentifierTooltipModalText03: "",
      defaultLocales: [],
      darkTheme: true,
      excelTemplateFileLink: "https://api.files.glitchedpolygons.com/api/v1/files/glitched-locale-server-excel-template.xlsx"
    }
  },
  mounted()
  {
    document.title = "Glitched Locale Server";

    this.darkTheme = JSON.parse(localStorage.getItem(Constants.localStorageKeyDarkTheme) ?? JSON.stringify(Constants.settingsDefaultDarkTheme));

    this.emitter.on(Constants.eventNameChangedTheme, darkTheme =>
    {
      this.darkTheme = darkTheme;
    });

    whoAmI().then(async response =>
    {
      await this.handleResponseWhoAmI(response);

    }).catch(async error =>
    {
      console.error("Failed to fetch user data from server! Error: " + JSON.stringify(error));

      await refreshAuthToken(this.$router);

      // Try again:
      whoAmI().then(response => this.handleResponseWhoAmI(response)).catch(async () => await this.$router.push("/logout"));
    });
  },
  methods: {
    getUtcNow,
    handleResponseWhoAmI: async function (response)
    {
      if (!response.ok)
      {
        if (response.status === 401)
        {
          await refreshAuthToken(this.$router);

          response = await whoAmI();

          if (!response.ok)
          {
            localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
            await this.$router.push("/logout");
            return;
          }
        }
        else
        {
          console.error(`Failed to fetch user data from server! Status code ${response.status}`);
          localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
          await this.$router.push("/logout");
          return;
        }
      }

      const responseEnvelope = await response.json();

      if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
      {
        console.error("Failed to fetch user data from server: response body has invalid format!");
        localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
        await this.$router.push("/logout");
        return;
      }

      const userResponseDto = responseEnvelope.items[0];

      this.defaultLocales = userResponseDto.defaultLocales ? userResponseDto.defaultLocales : [];

      if (this.defaultLocales.length < 1)
      {
        this.defaultLocales = [
          "en_US.UTF-8",
          "en_GB.UTF-8",
          "de_DE.UTF-8",
          "de_CH.UTF-8",
          "it_IT.UTF-8",
          "it_CH.UTF-8",
          "fr_FR.UTF-8",
          "fr_CH.UTF-8",
          "es_ES.UTF-8",
          "es_MX.UTF-8"
        ];
      }

      let pageSize = localStorage.getItem(Constants.localStorageKeyTranslationsListPageSize);

      if (!pageSize || isNaN(pageSize))
      {
        pageSize = 10;
      }

      this.pageSize = pageSize;

      this.refreshTranslationsList();

      if (!this.translationModal)
      {
        this.translationModal = new Modal(document.getElementById("editTranslationModal"));
      }

      if (!this.exportToExcelModal)
      {
        this.exportToExcelModal = new Modal(document.getElementById("exportToExcelModal"));
      }

      if (!this.importFromExcelModal)
      {
        this.importFromExcelModal = new Modal(document.getElementById("importFromExcelModal"));
      }
    },
    showTranslationDescriptionTooltipToast: function ()
    {
      new Toast(document.getElementById("translationDescriptionTooltipToast"), {delay: 8877}).show();
    },
    showLocaleIdentifierTooltipToast: function ()
    {
      new Toast(document.getElementById("localeIdentifierTooltipToast"), {delay: 9999}).show();
    },
    isDarkTheme: function ()
    {
      return JSON.parse(localStorage.getItem(Constants.localStorageKeyDarkTheme) ?? JSON.stringify(Constants.settingsDefaultDarkTheme));
    },
    hideTranslationModalToasts: function ()
    {
      new Toast(document.getElementById("translationModificationRequestFailedToast")).hide();
      new Toast(document.getElementById("translationModificationRequestSucceededToast")).hide();
    },
    hideExportToExcelToasts: function ()
    {
      new Toast(document.getElementById("exportToExcelFailedToast")).hide();
      new Toast(document.getElementById("exportToExcelSucceededToast")).hide();
    },
    hideImportFromExcelToasts: function ()
    {
      new Toast(document.getElementById("importFromExcelFailedToast")).hide();
      new Toast(document.getElementById("importFromExcelSucceededToast")).hide();
    },
    exportToExcel: function ()
    {
      if (this.exportToExcelPending)
      {
        return;
      }

      this.exportToExcelPending = true;
      this.exportToExcelErrorMessage = "";

      let query = `${Config.webApiBaseUrl}/api/v1/translations/export`;
      let userId = localStorage.getItem(Constants.localStorageKeyUserId);

      query += `?userId=${userId}`;

      if (this.exportKeyFilter)
      {
        query += `&keyFilter=${this.exportKeyFilter}`;
      }

      fetch
      (
          query,
          {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
            }
          }
      ).then(async response =>
      {
        if (response.ok)
        {
          var url = window.URL.createObjectURL(await response.blob());
          var a = document.createElement('a');
          a.href = url;
          a.download = `${getUtcNow()}-${userId}.xlsx`;
          document.body.appendChild(a); // Need to append the element to the DOM -> otherwise it won't work in Firefox!
          a.click();
          a.remove();  // Remove temporary element afterwards.

          this.exportToExcelModal.hide();

          new Toast(document.getElementById("exportToExcelSucceededToast"), {delay: 8877}).show();
        }
        else
        {
          this.exportToExcelErrorMessage = `reason unknown. Status code ${response.status}`;

          new Toast(document.getElementById("exportToExcelFailedToast"), {delay: 8877}).show();

          console.error(`Export to Excel request failed. Error: ${JSON.stringify(response)}`);
        }

        this.exportToExcelPending = false;
      }).catch(error =>
      {
        new Toast(document.getElementById("exportToExcelFailedToast"), {delay: 8877}).show();

        console.error(`Export to Excel request failed. Error: ${JSON.stringify(error)}`);

        this.exportToExcelPending = false;
      });
    },
    importFromExcel: function ()
    {
      if (this.importFromExcelPending)
      {
        return;
      }

      this.importFromExcelPending = true;
      this.importFromExcelErrorMessage = "";

      let query = `${Config.webApiBaseUrl}/api/v1/translations/import`;
      let excelFilePicker = document.getElementById("excelFileToImport");

      console.log(excelFilePicker);
      console.log(excelFilePicker.files);
      console.log(JSON.stringify(excelFilePicker.files));

      if (!excelFilePicker || !excelFilePicker.files || excelFilePicker.files.length !== 1)
      {
        this.importFromExcelErrorMessage = "Please select a valid Excel file to upload!";

        new Toast(document.getElementById("importFromExcelFailedToast"), {delay: 8877}).show();
        console.error(`Import from Excel failed. Error: ${this.importFromExcelErrorMessage}`);

        this.importFromExcelPending = false;
        return;
      }

      const formData = new FormData();

      formData.append("userId", localStorage.getItem(Constants.localStorageKeyUserId));
      formData.append("excelFile", excelFilePicker.files[0]);

      fetch
      (
          query,
          {
            method: "POST",
            body: formData,
            headers: {
              "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`,
            }
          }
      )
          .then
          (
              async response =>
              {
                if (response.ok)
                {
                  this.importFromExcelModal.hide();

                  new Toast(document.getElementById("importFromExcelSucceededToast"), {delay: 8877}).show();

                  this.importFromExcelPending = false;
                  excelFilePicker.value = null;

                  return;
                }

                const responseEnvelope = await response.json();

                if (responseEnvelope && responseEnvelope.errors)
                {
                  const error = responseEnvelope.errors[0];

                  switch (error.code)
                  {
                    case 60:
                    {
                      this.importFromExcelErrorMessage = "invalid file! Please ensure that the provided Excel spreadsheet has the correct format and values.";
                      break;
                    }
                    case 61:
                    {
                      this.importFromExcelErrorMessage = "missing header row. Please ensure that the provided Excel spreadsheet has the correct format and values.";
                      break;
                    }
                    case 62:
                    {
                      this.importFromExcelErrorMessage = "duplicate language columns found. Every language column must be unique (e.g. \"en_US.UTF-8\" may only appear once per spreadsheet)";
                      break;
                    }
                    case 63:
                    {
                      this.importFromExcelErrorMessage = "insufficient quota. The translations contained in the uploaded spreadsheet would exceed the available quota upon import. Operation cancelled.";
                      break;
                    }
                  }
                }

                this.importFromExcelPending = false;
                excelFilePicker.value = null;

                new Toast(document.getElementById("importFromExcelFailedToast"), {delay: 9999}).show();

                console.error(`Import from Excel request failed. Error: ${this.importFromExcelErrorMessage}`);
              }
          )
          .catch
          (
              error =>
              {
                new Toast(document.getElementById("importFromExcelFailedToast"), {delay: 9999}).show();

                console.error(`Import from Excel request failed. Error: ${JSON.stringify(error)}`);

                this.importFromExcelPending = false;
                excelFilePicker.value = null;
              }
          );
    },
    openExportTranslationsToExcelModal: function ()
    {
      this.exportToExcelModal.show();
    },
    openImportTranslationsFromExcelModal: function ()
    {
      const excelFilePicker = document.getElementById("excelFileToImport");

      if (excelFilePicker)
      {
        excelFilePicker.value = null;
      }

      this.importFromExcelModal.show();
    },
    refreshTranslationsList: function ()
    {
      if (this.loadingTranslations)
      {
        return;
      }

      this.loadingTranslations = true;

      let query = `${Config.webApiBaseUrl}/api/v1/translations`;

      query += `?page=${this.page}`;
      query += `&pageSize=${this.pageSize}`;
      query += `&orderBy=${this.orderBy}`;
      query += `&userId=${localStorage.getItem(Constants.localStorageKeyUserId)}`;
      query += `&keyFilter=${this.keyFilter}`;

      fetch(query, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        this.loadingTranslations = false;

        if (!response.ok)
        {
          if (response.status === 401)
          {
            await refreshAuthToken(this.$router);

            response = await fetch(query, {
              method: "GET",
              headers: {
                "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
              }
            });

            if (!response.ok)
            {
              localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
              await this.$router.push("/logout");
              return;
            }
          }
          else
          {
            console.error(`Failed to fetch translations list from server! Status code ${response.status}`);
            localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
            await this.$router.push("/logout");
            return;
          }
        }

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items)
        {
          localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
          await this.$router.push("/logout");
          return;
        }

        this.totalTranslationsCount = responseEnvelope.count;
        this.pageCount = pageCountFromTotal(this.totalTranslationsCount, this.pageSize);
        this.translations = responseEnvelope.items;

        let refresh = false;

        if (this.page > this.pageCount)
        {
          this.page = this.pageCount;
          refresh = true;
        }

        if (this.pageCount > 0 && this.page === 0)
        {
          this.page = 1;
          refresh = true;
        }

        if (refresh)
        {
          this.refreshTranslationsList();
        }

      }).catch(async () =>
      {
        this.loadingTranslations = false;
        await refreshAuthToken(this.$router);
      });
    },
    nextPage: function ()
    {
      if (this.page === this.pageCount)
      {
        return;
      }

      this.page++;
      this.refreshTranslationsList();
    },
    prevPage: function ()
    {
      if (this.page === 1)
      {
        return;
      }

      this.page--;
      this.refreshTranslationsList();
    },
    createNewTranslation: function ()
    {
      this.editedTranslation = null;
      this.loadingTranslation = false;
      this.clearTranslationModalFields();

      this.translationModal.show();
    },
    editTranslation: function (translation)
    {
      this.editedTranslation = null;
      this.loadingTranslation = true;
      this.clearTranslationModalFields();

      fetch(`${Config.webApiBaseUrl}/api/v1/translations/${localStorage.getItem(Constants.localStorageKeyUserId)}/${translation.key}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        if (!response.ok)
        {
          this.translationModal.hide();
          new Toast(document.getElementById("translationFetchRequestFailedToast"), {delay: 6677}).show();
          return;
        }

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
        {
          this.translationModal.hide();
          new Toast(document.getElementById("translationFetchRequestFailedToast"), {delay: 6677}).show();
          return;
        }

        const translationResponseDto = responseEnvelope.items[0];

        this.editedTranslation = translationResponseDto;
        this.editedTranslationId = translationResponseDto.id;
        this.editedTranslationKey = translationResponseDto.key;
        this.editedTranslationOwnerId = translationResponseDto.ownerId;
        this.editedTranslationDescription = translationResponseDto.description;
        this.editedTranslationCreationUTC = translationResponseDto.creationUTC;
        this.editedTranslationExpirationUTC = translationResponseDto.expirationUTC;
        this.editedTranslationLastModificationUTC = translationResponseDto.lastModificationUTC;

        this.$refs.editedTranslationKey.setValue(translationResponseDto.key);
        this.$refs.editedTranslationExpirationUTC.setUTC(translationResponseDto.expirationUTC);

        this.editedTranslationTranslations = [];

        const keys = Object.keys(translationResponseDto.translations);

        for (let i = 0; i < keys.length; ++i)
        {
          this.editedTranslationTranslations.push({
            [keys[i]]: translationResponseDto.translations[keys[i]]
          });
        }

        this.loadingTranslation = false;
      }).catch(error =>
      {
        this.loadingTranslation = false;
        this.translationModal.hide();
        new Toast(document.getElementById("translationFetchRequestFailedToast"), {delay: 6677}).show();
      });

      this.translationModal.show();
    },
    clearTranslationModalFields: function ()
    {
      this.$refs.editedTranslationKey.clear();
      this.$refs.editedTranslationExpirationUTC.clear();

      this.editedTranslationId = "";
      this.editedTranslationKey = "";
      this.editedTranslationOwnerId = "";
      this.editedTranslationDescription = "";
      this.editedTranslationTranslations = [];
      this.editedTranslationCreationUTC = 0;
      this.editedTranslationExpirationUTC = 0;
      this.editedTranslationLastModificationUTC = 0;
    },
    getLocalesFlatObjFromArray: function (array)
    {
      if (!array || array.length === 0)
      {
        return {};
      }

      let locales = {};

      for (let i = 0; i < array.length; ++i)
      {
        const translation = array[i];

        if (!translation || !Object.keys(translation)[0])
        {
          continue;
        }

        locales[Object.keys(translation)[0]] = translation[Object.keys(translation)[0]];
      }

      return locales;
    },
    submitTranslationCreationRequest: function ()
    {
      if (this.translationModificationPending)
      {
        return;
      }

      this.translationModificationPending = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/translations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        },
        body: JSON.stringify({
          ownerId: localStorage.getItem(Constants.localStorageKeyUserId),
          expirationUTC: this.editedTranslationExpirationUTC ? this.editedTranslationExpirationUTC : null,
          key: this.editedTranslationKey,
          description: this.editedTranslationDescription,
          translations: this.getLocalesFlatObjFromArray(this.editedTranslationTranslations)
        }),
      }).then(response =>
      {
        this.translationModificationPending = false;

        if (!response.ok)
        {
          new Toast(document.getElementById("translationCreationRequestFailedToast"), {delay: 6677}).show();
          return;
        }

        this.editedTranslation = null;
        this.translationModal.hide();

        new Toast(document.getElementById("translationCreationRequestSucceededToast"), {delay: 6677}).show();
        this.refreshTranslationsList();
      }).catch(error =>
      {
        this.translationModificationPending = false;
        new Toast(document.getElementById("translationCreationRequestFailedToast"), {delay: 6677}).show();
      });
    },
    submitTranslationModificationRequest: function ()
    {
      if (this.translationModificationPending)
      {
        return;
      }

      this.translationModificationPending = true;

      fetch(`${Config.webApiBaseUrl}/api/v1/translations`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        },
        body: JSON.stringify({
          id: this.editedTranslationId,
          ownerId: localStorage.getItem(Constants.localStorageKeyUserId),
          expirationUTC: this.editedTranslationExpirationUTC ? this.editedTranslationExpirationUTC : null,
          description: this.editedTranslationDescription,
          translations: this.getLocalesFlatObjFromArray(this.editedTranslationTranslations)
        }),
      }).then(response =>
      {
        this.translationModificationPending = false;

        if (!response.ok)
        {
          new Toast(document.getElementById("translationModificationRequestFailedToast"), {delay: 6677}).show();
          return;
        }

        this.refreshTranslationsList();
        new Toast(document.getElementById("translationModificationRequestSucceededToast"), {delay: 6677}).show();

        this.translationModificationCooldown = true;
        setTimeout(() =>
        {
          this.translationModificationCooldown = false;
        }, 1337);
      }).catch(() =>
      {
        this.translationModificationPending = false;
        new Toast(document.getElementById("translationModificationRequestFailedToast"), {delay: 6677}).show();
      });
    },
    refreshNoInvalidTranslationKeys: function ()
    {
      this.noInvalidTranslationKeys = true;

      for (let i = 0; i < this.editedTranslationTranslations.length; ++i)
      {
        const t = this.editedTranslationTranslations[i];

        if (!t || !Object.keys(t)[0])
        {
          this.noInvalidTranslationKeys = false;
          break;
        }
      }
    },
    onChangedTranslationKeyValue: function (translationKey)
    {
      this.editedTranslationKey = translationKey;
    },
    onChangedExpirationUtcValue: function (expUtc)
    {
      this.editedTranslationExpirationUTC = expUtc;
    },
    onClickedAddAllLocales: function ()
    {
      for (let i = 0; i < this.defaultLocales.length; ++i)
      {
        const localeId = this.defaultLocales[i];

        this.onClickedAddNewLocale(localeId, true);
      }
    },
    onClickedAddNewLocale: function (localeId = "", silent = false)
    {
      if (!localeId || localeId === "")
      {
        localeId = "(Insert locale identifier here)";
      }

      for (let i = 0; i < this.editedTranslationTranslations.length; ++i)
      {
        if (Object.keys(this.editedTranslationTranslations[i])[0] === localeId)
        {
          if (!silent)
          {
            alert(`Error: you already have the locale "${localeId}" in the list of locales for this translation!`);
          }
          return;
        }
      }

      this.editedTranslationTranslations.push({
        [localeId]: ""
      });
    },
    onClickedRemoveLocale: function (locale)
    {
      const index = this.editedTranslationTranslations.indexOf(locale);

      if (index !== -1)
      {
        this.editedTranslationTranslations.splice(index, 1);
      }
    },
    onClickedDeleteTranslation: function ()
    {
      if (!this.editedTranslation || !this.editedTranslationId)
      {
        return;
      }

      if (confirm(`Are you sure that you want to delete the translation "${this.editedTranslationKey}"?`))
      {
        fetch(`${Config.webApiBaseUrl}/api/v1/translations/${this.editedTranslationId}`, {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
          }
        }).then(response =>
        {
          if (!response.ok)
          {
            new Toast(document.getElementById("translationDeletionRequestFailedToast"), {delay: 6677}).show();
            return;
          }

          this.editedTranslation = null;
          this.translationModal.hide();

          new Toast(document.getElementById("translationDeletionRequestSucceededToast"), {delay: 6677}).show();
          this.refreshTranslationsList();
        }).catch(() =>
        {
          new Toast(document.getElementById("translationDeletionRequestFailedToast"), {delay: 6677}).show();
        });
      }
    },
    onChangeLocaleIdentifier: function (translation, event)
    {
      translation[event.target.value] = translation[Object.keys(translation)[0]];
      delete translation[Object.keys(translation)[0]];

      document.getElementById(`label_${this.editedTranslationTranslations.indexOf(translation)}`).innerHTML = event.target.value;

      this.refreshNoInvalidTranslationKeys();
    },
    onChangeLocaleTranslation: function (translation, event)
    {
      translation[Object.keys(translation)[0]] = event.target.value;

      this.refreshNoInvalidTranslationKeys();
    },
    toggleSortByColumn: function (columnId)
    {
      if (this.orderBy < columnId || this.orderBy > columnId + 1)
      {
        this.orderBy = columnId;
        return;
      }

      this.orderBy = this.orderBy === columnId ? columnId + 1 : columnId;
    }
  },
  watch: {
    pageSize: function (newPageSize)
    {
      localStorage.setItem(Constants.localStorageKeyTranslationsListPageSize, newPageSize);
    },
    keyFilter: debounce(function (newKeyFilter)
    {
      this.refreshTranslationsList();
    }, 384),
    orderBy: debounce(function (newOrderBy)
    {
      this.refreshTranslationsList();
    }, 384)
  }
}
</script>

<style scoped>

#editedTranslationDescriptionField {
  max-height: 192px;
}

#accordionTranslationLocales {
  margin-top: 12px;
}

fieldset {
  margin-top: -21px;
}

.pagination-group {
  display: flex;
  column-gap: 14px;
}

.pagination-group > p {
  margin-left: auto;
  text-align: right;
  font-size: 0.875rem;
}

@media (max-width: 576px) {
  .hide-on-mobile {
    display: none;
  }

  .pagination-group {
    display: block;
  }

  .page-size-dropdown {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .btn-group {
    width: 100%;
  }
}

.pagination-order-icon {
  cursor: pointer;
  margin-left: 4px;
}

.form-check-input,
.table-secondary,
.table-warning,
tr {
  cursor: pointer;
}

.container {
  padding-bottom: 16px;
}

.dropdown-item-locale-id {
  cursor: pointer;
}

#translationDescriptionTooltip,
#localeIdentifierTooltip {
  margin-left: 4px;
  scale: 96%;
  cursor: help;
  font-weight: bold;
}

#translationDescriptionTooltip:hover,
#localeIdentifierTooltip:hover {
  filter: brightness(0.9);
}

</style>
