<template>

  <router-view/>

</template>

<script>

import Login from "@/components/Login.vue"
import Logout from "@/components/Logout.vue";
import Settings from "@/components/Settings.vue"
import Translations from "@/components/Translations.vue"
import ThemeSelector from "@/components/ThemeSelector.vue";
import Constants from "@/constants";
import {refreshAuthToken} from "@/auth";

export default {
  name: "App",
  components: {
    Login,
    Logout,
    Settings,
    Translations,
    ThemeSelector,
  },
  data()
  {
    return {
      stylesheet: null,
      authRefreshInterval: null,
      lastAuthRefresh: new Date()
    }
  },
  methods: {
    authRefresh: function ()
    {
      const timestamp = new Date();

      if (this.lastAuthRefresh.getTime() + (1024 * 64) > timestamp.getTime())
      {
        return;
      }

      this.lastAuthRefresh = timestamp;

      refreshAuthToken(this.$router);
    }
  },
  mounted()
  {
    this.emitter.on(Constants.eventNameChangedTheme, darkTheme =>
    {
      this.stylesheet?.remove();

      this.stylesheet = document.createElement('link');
      this.stylesheet.rel = "stylesheet";
      this.stylesheet.type = "text/css";

      if (darkTheme)
      {
        this.stylesheet.href = "/css/darkly/bootswatch-darkly-v5.min.css";
      }
      else
      {
        this.stylesheet.href = "/css/flatly/bootswatch-flatly-v5.min.css";
      }

      document.head.appendChild(this.stylesheet);
    });

    setTimeout(this.authRefresh, 1337);
    window.addEventListener("focus", this.authRefresh);
    this.authRefreshInterval = setInterval(this.authRefresh, 1000 * Constants.authTokenRefreshIntervalSeconds);
  },
  beforeUnmount()
  {
    window.removeEventListener("focus", this.authRefresh);
    clearInterval(this.authRefreshInterval);
    this.authRefreshInterval = null;
  }
}

</script>

<style>

.container
{
  padding-bottom: 16px;
}

</style>
