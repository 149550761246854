<template>

  <ThemeSelector class="position-fixed top-0 end-0 p-3"
                 style-checked="background-color: rgb(41, 53, 66)"/>

  <div class="container">

    <div class="login-title">

      <img id="logo"
           alt="Logo"
           width="128"
           height="128"
           :src="logo">

      <h1>
        Welcome to the <span style="white-space: nowrap;">Glitched Locale Server!</span>
      </h1>

    </div>

    <div class="row">

      <div class="col-sm-4"></div>

      <div class="col-sm-4">

        <fieldset>

          <div class="form-group"
               style="position: absolute; top: -2048px;">

            <label class="form-label mt-4">
              Email
            </label>

            <input type="text"
                   class="form-control"
                   v-model="email"
                   name="email"
                   autocomplete="off"
                   v-on:keyup.enter="login()"
                   @focus="$event.target.select()"
                   id="email"
                   placeholder="">
          </div>

          <div class="form-group">

            <label class="form-label mt-4">
              Username
            </label>

            <input type="text"
                   class="form-control"
                   v-model="username"
                   v-on:keyup.enter="login()"
                   @focus="$event.target.select()"
                   required
                   id="username"
                   placeholder="">
          </div>

          <div class="form-group">

            <label class="form-label mt-4">
              Password
            </label>

            <input type="password"
                   class="form-control"
                   v-on:keyup.enter="login()"
                   v-model="password"
                   @focus="$event.target.select()"
                   required
                   placeholder="">
          </div>

          <div class="form-group">

            <label class="form-label mt-4">
              Two-Factor Authentication

              <sup>
                <span class="badge rounded-pill bg-primary"
                      id="totpTooltip"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#totpModal"
                      :title="totpModalText01 + '\n' + totpModalText02">
                  ?
                </span>
              </sup>
            </label>

            <input type="text"
                   class="form-control"
                   v-on:keyup.enter="login()"
                   v-model="totp"
                   @focus="$event.target.select()"
                   placeholder="">
          </div>

          <div class="form-group"
               style="margin-top: 16px;">

            <div class="form-check form-switch">

              <input class="form-check-input"
                     type="checkbox"
                     v-model="rememberMe"
                     @change="onChangeRememberMe"
                     id="flexSwitchRememberMe">

              <label class="form-check-label"
                     for="flexSwitchRememberMe">
                Remember me
              </label>
            </div>

          </div>

          <br/>

          <button type="submit"
                  id="loginButton"
                  class="btn btn-primary"
                  :disabled="loggingIn || (!username && !password)"
                  v-on:click="login()">

            <span class="spinner-border spinner-border-sm text-info"
                  :hidden="!loggingIn"
                  style="margin-right: 3px;"
                  role="status">

              <span class="visually-hidden">
                Logging in...
              </span>

            </span>

            Login

          </button>

        </fieldset>
      </div>

      <div class="col-sm-4"></div>

    </div>

    <!------------------------- MODALS ------------------------->

    <div class="modal fade"
         id="totpModal"
         tabindex="-1"
         aria-labelledby="totpModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="totpModalLabel">
              Two-Factor Authentication
            </h5>

            <button type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Dismiss"/>
          </div>

          <div class="modal-body">
            <p>
              {{ totpModalText01 }}
            </p>
            <p>
              {{ totpModalText02 }}
            </p>
          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-primary"
                    data-bs-dismiss="modal">
              Okay
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="modal fade"
         id="serverMessageModal"
         tabindex="-1"
         data-bs-keyboard="false"
         data-bs-backdrop="static"
         aria-labelledby="serverMessageModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">

            <h5 class="modal-title"
                id="serverMessageModalLabel">
              Login successful <span>✔️</span>
            </h5>
          </div>

          <div class="modal-body">
            <p>
              Server message:
            </p>
            <pre class="login-server-message">{{ serverMessage }}
            </pre>
          </div>

          <div class="modal-footer">

            <button type="button"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                    v-on:click="dismissServerMessage()">
              Continue
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="position-fixed bottom-0 end-0 p-3"
         style="z-index: 11">
      <div id="loginFailedToast"
           class="toast"
           role="alert"
           aria-live="assertive"
           aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            Failure <span>❗️</span>
          </strong>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"></button>
        </div>
        <div class="toast-body"
             style="border-top: 1px solid black">
          Login attempt failed. Please ensure that your credentials are correct or try again later!
        </div>
      </div>
    </div>

  </div>

  <br/>

</template>

<script>

import Config from "../../public/js/config";
import Constants from "@/constants";
import {Modal, Toast} from "bootstrap";
import ThemeSelector from "@/components/ThemeSelector";
import {sha1} from "@/sha1";
import {getUtcNow} from "@/utc";

export default {
  name: "Login",
  props: {
    darkTheme: Boolean
  },
  components: {
    ThemeSelector
  },
  data()
  {
    return {
      loggingIn: false,
      rememberMe: false,
      email: "",
      username: "",
      password: "",
      totp: "",
      serverMessage: "",
      totpModalText01: "Enter your 2FA token into this field.",
      totpModalText02: "If you don't have 2FA enabled, you can leave this empty.",
      logo: "/img/logo-dark-theme.png",
    }
  },
  mounted()
  {
    document.title = "Glitched Locale Server - Login";

    this.emitter.on(Constants.eventNameChangedTheme, darkTheme =>
    {
      this.logo = darkTheme === true ? "/img/logo-dark-theme.png" : "/img/logo-light-theme.png";
    });

    this.logo = localStorage.getItem(Constants.localStorageKeyDarkTheme) === "true" ? "/img/logo-dark-theme.png" : "/img/logo-light-theme.png";

    document.getElementById("username")?.focus();
  },
  methods: {
    clearLoginForm: function (focusUsernameField = true)
    {
      this.username = "";
      this.password = "";
      if (focusUsernameField)
      {
        document.getElementById("username")?.focus();
      }
    },

    onChangeRememberMe: function ()
    {
      // console.log(this.rememberMe ? "Remember me set to TRUE" : "Remember me set to FALSE");
    },

    dismissServerMessage: function ()
    {
      const returnUrl = localStorage.getItem(Constants.localStorageKeyLoginReturnUrl);
      localStorage.removeItem(Constants.localStorageKeyLoginReturnUrl);
      this.$router.push(returnUrl ? returnUrl : "/");
    },

    login: function ()
    {
      if (this.loggingIn)
      {
        return;
      }

      this.loggingIn = true;

      setTimeout(() =>
      {
        this.email = ""
      }, 128);
      setTimeout(() =>
      {
        fetch(`${Config.webApiBaseUrl}/api/v1/login`, {
          method: "POST",
          body: JSON.stringify({
            sessionId: sha1(getUtcNow().toString()),
            email: this.email,
            username: this.username,
            password: this.password,
            totp: this.totp
          }),
          headers: {
            "Content-Type": "application/json; charset=UTF-8"
          }
        }).then(async response =>
        {
          this.loggingIn = false;

          if (response.status !== 200)
          {
            this.clearLoginForm();
            new Toast(document.getElementById('loginFailedToast')).show();
            return;
          }

          const responseEnvelope = await response.json();

          if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
          {
            await this.$router.push("/logout");
            return;
          }

          const loginResponseDto = responseEnvelope.items[0];

          localStorage.setItem(Constants.localStorageKeyUserId, loginResponseDto?.userId);
          localStorage.setItem(Constants.localStorageKeyAuthToken, loginResponseDto?.token);
          localStorage.setItem(Constants.localStorageKeyServerVersionNumber, loginResponseDto?.serverVersionNumber);

          if (this.rememberMe)
          {
            localStorage.setItem(Constants.localStorageKeyDefibrillatorToken, loginResponseDto?.defibrillatorToken);
          }
          else
          {
            localStorage.removeItem(Constants.localStorageKeyDefibrillatorToken);
          }

          if (loginResponseDto.serverMessage)
          {
            this.serverMessage = loginResponseDto.serverMessage.replaceAll("<script", "_script");
            new Modal(document.getElementById('serverMessageModal')).show();
            return;
          }

          this.dismissServerMessage();

        }).catch(() =>
        {
          setTimeout(() =>
          {
            this.clearLoginForm();
            this.loggingIn = false;
            new Toast(document.getElementById("loginFailedToast")).show();
          }, 1337);
        });
      }, 256);
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#logo {
  margin-top: 64px;
}

.login-title {
  text-align: center;
}

#totpTooltip {
  margin-left: 4px;
  scale: 96%;
  cursor: help;
  font-weight: bold;
}

#loginButton {
  padding-left: 1.33rem;
  padding-right: 1.33rem;
  float: right;
}

@media (max-width: 768px) {
  #loginButton {
    width: 100%;
  }
}

.login-server-message {
  padding: 16px;
  overflow-y: auto;
  max-height: 150px;
  border-radius: 6px;
  white-space: pre-line;
}

.container {
  padding-bottom: 16px;
}

</style>
