<template>

  <div class="form-group">

    <label class="form-label mt-4">
      {{ label }} &nbsp;

      <span :hidden="passwordStrength !== 1"
            title="Password too weak!"
            class="pw-strength-check-symbol">
        🔴
      </span>

      <span :hidden="passwordStrength !== 2"
            title="Password kinda weak; consider making it stronger by using lowercase, UPPERCASE, numbers as well as special characters!"
            class="pw-strength-check-symbol">
        🟡
      </span>

      <span :hidden="passwordStrength !== 3"
            title="Password strength sufficient."
            class="pw-strength-check-symbol">
        🟢
      </span>
    </label>

    <input type="password"
           :id="inputId"
           class="form-control"
           v-on:keyup.enter="onPressedEnter()"
           v-model="value"
           @focus="$event.target.select()"
           placeholder="">
  </div>

</template>

<script>

export default {
  name: "PasswordField",
  props: {
    checkStrength: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Password"
    },
    inputId: {
      type: String,
      default: "password"
    }
  },
  emits: ["onPressedEnter", "onChangedPasswordValue"],
  data()
  {
    return {
      value: "",
      passwordStrength: 0
    }
  },
  methods: {
    onPressedEnter: function ()
    {
      this.$emit("onPressedEnter");
    },
    clear: function ()
    {
      this.value = "";
      this.passwordStrength = 0;
    }
  },
  watch: {
    value: function (newValue)
    {
      this.passwordStrength = 2;

      if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(newValue))
      {
        this.passwordStrength = 3;
      }

      if (newValue.length < 7)
      {
        this.passwordStrength = 1;
      }

      if (!newValue)
      {
        this.passwordStrength = 0;
      }

      this.$emit("onChangedPasswordValue", newValue, this.passwordStrength);
    }
  },
}

</script>

<style scoped>

.pw-strength-check-symbol
{
  cursor: help;
}

.pw-strength-check-symbol:hover
{
  filter: brightness(80%);
}

</style>
