<template>

  <div class="form-group">

    <label class="form-label mt-4">
      Maximum user quota (bytes)
      <span title="Add one megabyte of quota to the current value"
            @click="addMaxQuota(1048576)"
            class="badge rounded-pill bg-primary badge-btn">
                  +1MiB
      </span>

      <span title="Add one gigabyte of quota to the current value"
            @click="addMaxQuota(1073741824)"
            class="badge rounded-pill bg-primary badge-btn">
                  +1GiB
      </span>
    </label>

    <input type="text"
           class="form-control"
           v-model="maxQuotaBytes"
           v-on:keyup.enter="onPressedEnter()"
           @focus="$event.target.select()"
           placeholder="">

    <small>
      {{
        maxQuotaBytes && maxQuotaBytes > 0 ? `User has a maximum quota of ${getNicelyFormattedMaxQuotaString()}` : "User has unlimited quota"
      }}
    </small>
  </div>

</template>

<script>

import {getNicelyFormattedFileSize} from "@/bytes";

export default {
  name: "MaxQuotaBytesField",
  data()
  {
    return {
      maxQuotaBytes: 0,
    }
  },
  emits: ["onPressedEnter", "onChangedMaxQuotaBytesValue"],
  methods: {
    addMaxQuota: function (bytes)
    {
      if (!this.maxQuotaBytes)
      {
        this.maxQuotaBytes = 0;
      }

      let n = 0;
      n += this.maxQuotaBytes;
      n += bytes;
      this.maxQuotaBytes = n;
    },
    getNicelyFormattedMaxQuotaString: function ()
    {
      return getNicelyFormattedFileSize(this.maxQuotaBytes);
    },
    onPressedEnter: function ()
    {
      this.$emit("onPressedEnter");
    },
    clear: function ()
    {
      this.maxQuotaBytes = 0;
    }
  },
  watch: {
    maxQuotaBytes: function (newValue)
    {
      if (!newValue || isNaN(newValue))
      {
        this.maxQuotaBytes = 0;
      }

      this.$emit("onChangedMaxQuotaBytesValue", newValue);
    },
  }
}

</script>

<style scoped>

.badge-btn
{
  margin-left: 2px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.badge-btn:hover
{
  cursor: pointer;
  filter: brightness(0.8);
}

</style>
