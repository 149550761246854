<template>
  <div style="display: flex;"
       title="Dark theme FTW!">
    <span>
      🌞
    </span>

    <div id="form-div"
         class="form-check form-switch">

      <input class="form-check-input"
             type="checkbox"
             id="darkThemeSwitch"
             :style="darkThemeChecked ? styleChecked : styleUnchecked"
             v-model="darkThemeChecked">
    </div>

    <span>
      🌚
    </span>
  </div>
</template>

<script>

import Constants from "@/constants";

export default {
  name: "ThemeSelector",
  props: {
    styleChecked: String,
    styleUnchecked: String
  },
  data()
  {
    return {
      darkThemeChecked: Constants.settingsDefaultDarkTheme
    }
  },
  watch: {
    darkThemeChecked(newValue, oldValue)
    {
      console.log(`${new Date().toUTCString()}: Changed "darkTheme" from ${oldValue} to ${newValue}.`);
      localStorage.setItem(Constants.localStorageKeyDarkTheme, JSON.stringify(newValue));
      this.emitter.emit(Constants.eventNameChangedTheme, newValue);
    }
  },
  mounted()
  {
    let darkTheme = Constants.settingsDefaultDarkTheme;

    try
    {
      darkTheme = JSON.parse(localStorage.getItem(Constants.localStorageKeyDarkTheme) ?? JSON.stringify(Constants.settingsDefaultDarkTheme));
    }
    catch
    {
      darkTheme = Constants.settingsDefaultDarkTheme;
    }

    localStorage.setItem(Constants.localStorageKeyDarkTheme, JSON.stringify(darkTheme));

    this.darkThemeChecked = darkTheme;
    this.emitter.emit(Constants.eventNameChangedTheme, darkTheme);
  }
}

</script>

<style scoped>

#form-div
{
  margin-left: 8px;
}

</style>
