<template>

  <div class="form-group">

    <label class="form-label mt-4">
      Expiration date
      <span @click="addToExpUTC(604800)"
            title="Add one week to the expiration date"
            class="badge rounded-pill bg-primary badge-btn">
                  +1w
      </span>
      <span @click="addToExpUTC(31536000)"
            title="Add one year to the expiration date"
            class="badge rounded-pill bg-primary badge-btn">
                  +1y
      </span>
    </label>

    <input type="datetime-local"
           class="form-control"
           v-model="expirationDateTime"
           :min="getMinExpirationDateTime()">

    <small>
      {{
        expirationUTC && expirationUTC > 0 ? `Will expire on ${new Date(expirationUTC * 1000).toUTCString()}` : "Does not expire"
      }}
    </small>
  </div>

</template>

<script>

import {debounce} from "@/debounce";
import {getDateFromUtc, getDateTimeFromDate, getDateTimeFromUtc, getUtcFromDate, getUtcNow} from "@/utc";

export default {
  name: "ExpirationField",
  data()
  {
    return {
      expirationUTC: 0,
      expirationDateTime: null,
      minExpirationDate: getDateFromUtc(getUtcNow() + 172800),
    }
  },
  emits: ["onPressedEnter", "onChangedExpirationUtcValue"],
  methods: {
    addToExpUTC: function (secondsToAdd)
    {
      const utcNow = getUtcNow();
      const expirationUTC = getUtcFromDate(new Date(this.expirationDateTime ? this.expirationDateTime : 0));

      this.expirationDateTime =
          expirationUTC + secondsToAdd < utcNow
              ? getDateTimeFromUtc(utcNow + secondsToAdd)
              : getDateTimeFromUtc(expirationUTC + secondsToAdd);
    },
    getMinExpirationDateTime: function ()
    {
      return getDateTimeFromDate(this.minExpirationDate);
    },
    onPressedEnter: function ()
    {
      this.$emit("onPressedEnter");
    },
    setUTC: function (utc)
    {
      if (!utc || isNaN(utc))
      {
        this.expirationDateTime = null;
        return;
      }

      this.expirationDateTime = getDateTimeFromUtc(utc);
    },
    clear: function ()
    {
      this.expirationDateTime = null;
    }
  },
  watch: {
    expirationDateTime: debounce(function (newExpirationDateTime)
    {
      if (!newExpirationDateTime)
      {
        this.expirationUTC = 0;
        this.$emit("onChangedExpirationUtcValue", null);
        return;
      }

      const currentDate = new Date();
      const newExpirationDate = new Date(newExpirationDateTime);

      if (newExpirationDate < currentDate)
      {
        this.expirationUTC = 0;
        this.expirationDateTime = null;
        return;
      }

      this.expirationUTC = getUtcFromDate(newExpirationDate);
      this.$emit("onChangedExpirationUtcValue", this.expirationUTC);
    }, 256)
  }
}
</script>

<style scoped>

.badge-btn
{
  margin-left: 2px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.badge-btn:hover
{
  cursor: pointer;
  filter: brightness(0.8);
}

[type="date"]
{
  opacity: 0;
}

[type="date"]::-webkit-inner-spin-button
{
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator
{
  display: none;
}

</style>
