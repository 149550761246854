export function getNicelyFormattedFileSize(bytes)
{
    if (!bytes || bytes === 0)
    {
        return "0 B";
    }

    const units = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    const unitCount = units.length;

    let result = bytes;
    let unitIndex = 0;

    while (result >= 1024 && unitCount > unitIndex + 1)
    {
        result /= 1024;
        ++unitIndex;
    }

    return `${result.toFixed(unitIndex === 0 ? 0 : 2)} ${units[unitIndex]}`;
}
