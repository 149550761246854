<template>

  <div class="form-group">

    <label class="form-label mt-4" :hidden="!label || label.length === 0">
      {{ label }} &nbsp;

      <div class="spinner-border spinner-border-sm text-info"
           :hidden="usernameAvailabilityCheck !== 1"
           role="status">
            <span class="visually-hidden">
              Checking username availability...
            </span>
      </div>

      <span :hidden="usernameAvailabilityCheck !== 2"
            class="key-availability-check-symbol"
            title="Username is available">
                        ✔️
      </span>

      <span :hidden="usernameAvailabilityCheck !== 3"
            title="Username already taken, sorry :/"
            class="key-availability-check-symbol">
                        ⛔
      </span>

    </label>


    <div class="input-group">
      <input type="text"
             class="form-control"
             v-model="username"
             :placeholder="placeholder"
             v-on:keyup.enter="onPressedEnter()"
             v-on:input="usernameAvailabilityCheck = (username ? 1 : 0);"
             @focus="$event.target.select()">

      <button class="btn btn-secondary"
              type="button"
              :disabled="!username"
              v-on:click="username = ''; usernameAvailabilityCheck = 0;">
        X
      </button>
    </div>

  </div>

</template>

<script>

import {debounce} from "@/debounce";
import Constants from "@/constants";
import Config from "../../../public/js/config";

export default {
  name: "UsernameField",
  props: {
    label: {
      default: "Username",
      type: String
    },
    placeholder: {
      default: "",
      type: String
    }
  },
  methods: {
    onPressedEnter: function ()
    {
      this.$emit("onPressedEnter");
    },
    clear: function ()
    {
      this.username = "";
      this.usernameAvailabilityCheck = 0;
    }
  },
  data()
  {
    return {
      username: "",
      usernameAvailabilityCheck: 0
    }
  },
  emits: ["onChangedUsernameValue", "onPressedEnter"],
  watch: {
    username: debounce(function (newValue)
    {
      if (!newValue)
      {
        this.$emit("onChangedUsernameValue", newValue, false);
        return;
      }
      fetch(`${Config.webApiBaseUrl}/api/v1/users/username/${newValue}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(Constants.localStorageKeyAuthToken)}`
        }
      }).then(async response =>
      {
        if (!response.ok)
        {
          this.usernameAvailabilityCheck = 3;
          return;
        }

        const responseEnvelope = await response.json();

        if (!responseEnvelope || !responseEnvelope.items || responseEnvelope.items.length !== 1)
        {
          await this.$router.push("/logout");
          return;
        }

        const isAvailable = responseEnvelope.items[0].available === true;

        this.usernameAvailabilityCheck = isAvailable ? 2 : 3;
        this.$emit("onChangedUsernameValue", newValue, isAvailable);

      }).catch(() =>
      {
        this.usernameAvailabilityCheck = 3;
      });
    }, 1024)
  }
}
</script>

<style scoped>

.key-availability-check-symbol
{
  cursor: help;
}

.key-availability-check-symbol:hover
{
  filter: brightness(80%);
}

</style>
