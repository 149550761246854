<template>

  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container-fluid">

      <router-link class="navbar-brand" to="/">
        Glitched Locale Server
      </router-link>

      <button class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarColor01"
              aria-controls="navbarColor01"
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">
              Translations
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/settings">
              Settings
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/logout">
              Logout
            </router-link>
          </li>
        </ul>

        <ThemeSelector class="theme-selector-mobile"
                       style-checked="background-color: rgb(34, 34, 34)"/>
      </div>

      <ThemeSelector class="theme-selector"
                     style-checked="background-color: rgb(34, 34, 34)"/>
    </div>
  </nav>


</template>

<script>

import ThemeSelector from "@/components/ThemeSelector.vue";

export default {
  name: "Nav",
  components: {
    ThemeSelector
  }
}

</script>

<style scoped>

nav
{
  margin-bottom: 32px;
}

.theme-selector
{
  margin-top: 4px;
}

.theme-selector-mobile
{
  margin-top: 12px;
  display: none !important;
}

@media (max-width: 991px)
{
  .theme-selector-mobile
  {
    display: flex !important;
  }

  .theme-selector
  {
    display: none !important;
  }
}

</style>
